var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Login V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('div',{staticClass:"d-flex justify-content-center mb-3"},[_c('b-img',{staticClass:"logo",attrs:{"src":_vm.appLogoImage,"alt":"logo","width":"250"}})],1),_c('b-card-title',{staticClass:"mb-1 font-weight-bold",attrs:{"title-tag":"h2"}},[_vm._v(" ¡Bienvenido! 👋 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Tu nueva forma de pagar y cobrar. ")]),_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Email o Teléfono celular","label-for":"login-email"}},[_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required|email_or_phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-input',{attrs:{"id":"login-email","state":errors.length > 0 ? false : valid ? true : null,"name":"login-email","placeholder":"john@example.com ó 5555555555","tabindex":"1"},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"login-password"}},[_vm._v("Contraseña")]),_c('b-link',{attrs:{"to":{ name: 'auth-forgot-password' }}},[_c('small',[_vm._v("¿Olvidaste tu contraseña?")])])],1),_c('validation-provider',{attrs:{"name":"Password","vid":"password","rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-password","state":errors.length > 0 ? false : valid ? true : null,"type":_vm.passwordFieldType,"name":"login-password","placeholder":"Password","tabindex":"2"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{staticClass:"mt-3",attrs:{"type":"submit","variant":"primary","block":"","disabled":invalid}},[_vm._v(" Iniciar sesión ")])],1)]}}])}),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('b-row',[_c('span',[_vm._v("¿Quieres comprar en línea? ")])]),_c('b-link',{attrs:{"to":{ name: 'auth-register-client' }}},[_c('h4',[_c('span',{staticClass:"badge badge-success"},[_vm._v(" Registrate como cliente")])])])],1),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('b-row',[_c('span',[_vm._v("¿Tienes un negocio y quieres usar nuestro sistema? ")])]),_c('b-link',{attrs:{"to":{ name: 'auth-register-establishment' }}},[_c('h4',[_c('span',{staticClass:"badge badge-info"},[_vm._v(" Registrate como negocio")])])])],1),_c('div',{staticClass:"divider-text mt-2"}),_c('div',{staticClass:"auth-footer-btn d-flex justify-content-center text-center"},[_c('p',[_vm._v(" Al registrarse, acepta nuestros: "),_c('b-button',{attrs:{"block":"","to":{ name: 'terms-of-use' },"size":"sm"}},[_c('i',{staticClass:"fas fa-chart-line mr-1"}),_vm._v(" Términos y condiciones ")]),_c('b-button',{attrs:{"block":"","to":{ name: 'privacy-policy' },"size":"sm"}},[_c('i',{staticClass:"fas fa-chart-line mr-1"}),_vm._v(" Aviso de Privacidad ")])],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }