<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <div class="d-flex justify-content-center mb-3">
            <b-img :src="appLogoImage" alt="logo" width="250" class="logo" />
          </div>
          <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
            ¡Bienvenido! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Tu nueva forma de pagar y cobrar.
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- email -->
              <b-form-group
                label="Email o Teléfono celular"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors, valid }"
                  name="Email"
                  vid="email"
                  rules="required|email_or_phone"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : valid ? true : null"
                    name="login-email"
                    placeholder="john@example.com ó 5555555555"
                    tabindex="1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Contraseña</label>
                  <b-link :to="{ name: 'auth-forgot-password' }">
                    <small>¿Olvidaste tu contraseña?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors, valid }"
                  name="Password"
                  vid="password"
                  rules="required|min:6"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : valid ? true : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                      tabindex="2"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                class="mt-3"
                block
                :disabled="invalid"
              >
                Iniciar sesión
              </b-button>
            </b-form>
          </validation-observer>

          <!--           <b-card-text class="text-center mt-2">
            <span>¿Eres padre de familia? </span>
            <b-link :to="{ name: 'auth-register' }">
              <span>&nbsp;Registra el WallEat de tu hijo</span>
            </b-link>
          </b-card-text>
 -->
          <b-card-text class="text-center mt-2">
            <b-row><span>¿Quieres comprar en línea? </span></b-row>
            <b-link :to="{ name: 'auth-register-client' }">
              <h4>
                <span class="badge badge-success"
                  >&nbsp;Registrate como cliente</span
                >
              </h4>
            </b-link>
          </b-card-text>
          <b-card-text class="text-center mt-2">
            <b-row
              ><span
                >¿Tienes un negocio y quieres usar nuestro sistema?
              </span></b-row
            >
            <b-link :to="{ name: 'auth-register-establishment' }">
              <h4>
                <span class="badge badge-info"
                  >&nbsp;Registrate como negocio</span
                >
              </h4>
            </b-link>
          </b-card-text>

          <!-- divider -->
          <div class="divider-text mt-2" />

          <div
            class="auth-footer-btn d-flex justify-content-center text-center"
          >
            <p>
              Al registrarse, acepta nuestros:
              <b-button block :to="{ name: 'terms-of-use' }" size="sm">
                <i class="fas fa-chart-line mr-1" />
                Términos y condiciones
              </b-button>
              <b-button block :to="{ name: 'privacy-policy' }" size="sm">
                <i class="fas fa-chart-line mr-1" />
                Aviso de Privacidad
              </b-button>
            </p>
          </div>

          <!--<div class="divider my-2 text-white">
            <b-button
              v-if="deferredPrompt"
              ref="addBtn"
              @click="clickCallback"
            >
              Install app
            </b-button>
          </div>
           <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div> -->

          <!-- social buttons -->
          <!-- <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { mapActions } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import { VBTooltip } from "bootstrap-vue";

import useJwt from "@/auth/jwt/useJwt";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import { $themeConfig } from "@themeConfig";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  setup() {
    const { appName, appLogoImage } = $themeConfig.app;
    return {
      appName,
      appLogoImage,
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    ValidationProvider,
    ValidationObserver,

    VBTooltip,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),

      // validation rules
      required,
      email,
      deferredPrompt: null,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  created() {
    this.captureEvent();
  },
  /*mounted() {
    const device = this.getMobileOperatingSystem()
    if (device === 'Android') {
      this.$swal({
        title: '¿Te gustaría obtener la app?',
        text: 'Hemos detectado que estás desde un dispositivo móvil',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '¡Descargar!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          window.open('https://play.google.com/store/apps/details?id=com.mywalleat.app&hl=es_MX', '_blank')
        }
      })
    } else if(device === 'iOS')  { 
      this.$swal({
        title: '¿Te gustaría obtener la app?',
        html: 'Para instalar está aplicación, toca el botón de <strong>Compartir</strong> en tu en tu pantalla y selecciona <strong>Añadir a pantalla de inicio</strong>.',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
    }
  },*/
  methods: {
    getMobileOperatingSystem() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
      }
      if (/android/i.test(userAgent)) {
        return "Android";
      }
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }
      return "unknown";
    },
    captureEvent() {
      window.addEventListener("beforeinstallprompt", (e) => {
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault();
        // Stash the event so it can be triggered later.
        this.deferredPrompt = e;
      });
    },
    clickCallback() {
      // Show the prompt
      this.deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      this.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          // Add analyticcs event
          this.$gtag.event("add_to_home_screen");
        }
        this.deferredPrompt = null;
      });
    },
    ...mapActions("auth", ["signIn"]),
    ...mapActions("notifications", ["updateFirebaseToken"]),
    ...mapActions("users", ["attachReferral"]),
    login() {
      let emailValidation = false;
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.userEmail)) {
        emailValidation = true;
      }
      const that = this;
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          this.signIn({
            email: emailValidation === true ? this.userEmail : null,
            phone_number: emailValidation === false ? this.userEmail : null,
            password: this.password,
          })
            .then((response) => {
              const userData = response;
              useJwt.setToken(response.token);
              useJwt.setRefreshToken(response.token);
              localStorage.setItem("userData", JSON.stringify(userData));
              const referralToken = localStorage.getItem("referralToken");
              if (referralToken) {
                const decodedToken = decodeURIComponent(referralToken);
                this.attachReferral({ encrypted_message: decodedToken }).then(
                  (res) => {
                    if (res.store_id) {
                      localStorage.removeItem("referralToken");
                      this.$router.push({
                        name: "e-commerce",
                        params: { store_id: res.store_id },
                      });
                    }
                  }
                );
              }
              if (
                that.$route.query.redirectFrom &&
                userData.role_name !== "establishment_admin"
              ) {
                that.$router
                  .replace(that.$route.query.redirectFrom)
                  .then(() => {
                    that.updateFirebaseToken();
                  });
              } else {
                this.$router
                  .replace(getHomeRouteForLoggedInUser(userData))
                  .then(() => {
                    this.updateFirebaseToken();
                  });
              }
            })
            .catch((error) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-center",
                props: {
                  title: "Error",
                  icon: "CoffeeIcon",
                  variant: "danger",
                  text: error.response.data.messages,
                },
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.add-button {
  position: absolute;
}

.logo {
  filter: invert(0.1);
  transition: filter 0.3s ease;
}

.dark-layout {
  .logo {
    filter: none;
  }
}
</style>
